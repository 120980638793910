import "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"

import { type TurboSessionWithDrive } from "./types"
import { initSearch } from "../components/elm_search"
import Loader from "../components/loader"
import FiltersFormController from "../controllers/search/filters_form_controller"
import FiltersModalController from "../controllers/search/filters_modal_controller"
import ModalTriggerController from "../controllers/modal_trigger_controller"
import PriceFilterController from "../controllers/search/price_filter_controller"
import SearchMapController from "../controllers/search/search_map_controller"
import { pushGa4Event } from "../helpers/ga4_helper"
import { ENTRY, prefetchEntry } from "../helpers/prefetch"
import toggleContent from '../helpers/toggle_content'
import { isMobile } from '../helpers/window_size'

const initBumpers = () : void => {
  document.querySelectorAll(".js-search-bumper").forEach(bumper => {
    const dismissButton = <HTMLElement>bumper.querySelector("button[data-dismiss]")
    const { ga4EventGroup } = dismissButton?.dataset || {}
    if (!dismissButton) return

    // Presence of element is sufficient to trigger a "view"
    if (ga4EventGroup) pushGa4Event({ event: "view_content", content_type: ga4EventGroup })

    dismissButton.addEventListener("click", () => {
      dismissButton.closest(".offer--bumper")?.classList.add("collapsed")
    })
  })
}

const prefetchVenues = () : void => {
  document.addEventListener("DOMContentLoaded", () => prefetchEntry(ENTRY.VENUES))
}

/* This function adds an overlay for links within the search results container.
 * It doesn't remove that overlay because a click on those is a full page reload. */
const addResultsLinkListeners = () : void => {
  const loader = new Loader()

  document.addEventListener("click", (e: Event) => {
    const el = (e.target as HTMLElement)?.closest(".js-search-results a")
    if (!el) return

    if (el.hasAttribute("target")) {
      if (isMobile()) {
        el.removeAttribute("target")
        loader.start()
      }
    } else {
      loader.start()
    }
  })
}

const setupTurbo = (): void => {
  const TurboSession = Turbo.session as TurboSessionWithDrive
  TurboSession.drive = false
}

const setupStimulus = (): void => {
  const app = Application.start()
  app.register("filters-form", FiltersFormController)
  app.register("filters-modal", FiltersModalController)
  app.register("filters-modal-trigger", ModalTriggerController)
  app.register("price-filter", PriceFilterController)
  app.register("search-map", SearchMapController)
}

setupTurbo()
setupStimulus()
initSearch()
toggleContent(document)
initBumpers()
prefetchVenues()
addResultsLinkListeners()
