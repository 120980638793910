import { Controller } from "@hotwired/stimulus"

type GoogleMap = google.maps.Map
type MapsLibrary = google.maps.MapsLibrary

const HIDDEN = "hidden"
const MAP_OPEN_CLASS = "--map-open"

export default class extends Controller {
  static targets = ["map"]
  declare readonly mapTarget: HTMLElement

  private googleMap?: GoogleMap

  hide(): void {
    this.mapTarget.setAttribute(HIDDEN, "")
    document.body.classList.remove(MAP_OPEN_CLASS)
  }

  async show(): Promise<void> {
    if (!this.googleMap) {
      await this.initGoogleMap()
    }

    this.mapTarget.removeAttribute(HIDDEN)
    document.body.classList.add(MAP_OPEN_CLASS)
  }

  private async initGoogleMap(): Promise<void> {
    const DEFAULT_POSITION = {
      center: { lat: 53.765762, lng: -2.692337 },
      zoom: 6
    }
    const { Map: GoogleMap } = await window.google.maps.importLibrary("maps") as MapsLibrary

    this.googleMap = new GoogleMap(
      document.getElementById("js-google-map") as HTMLElement,
      {
        ...DEFAULT_POSITION,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      }
    )
  }
}
