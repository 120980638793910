import { Controller } from "@hotwired/stimulus"
import { filteredNavigationType, selectContentEvent } from "../../helpers/ga4_helper"

export default class PriceFilterController extends Controller<HTMLDivElement> {
  declare readonly lowerInputTarget: HTMLInputElement
  declare readonly lowerLabelTarget: HTMLSpanElement
  declare readonly upperInputTarget: HTMLInputElement
  declare readonly upperLabelTarget: HTMLSpanElement
  declare readonly activeTrackTarget: HTMLDivElement
  declare readonly priceLinkTarget: HTMLAnchorElement
  declare stepValue: number
  declare maxValue: number

  static RANGE_SLIDER_THUMBNAIL_SIZE = 25 // See `$range-slider-thumb-size`
  static targets = [
    "lowerInput",
    "lowerLabel",
    "upperInput",
    "upperLabel",
    "activeTrack",
    "priceLink"
  ]

  static values = {
    max: Number,
    step: Number
  }

  connect(): void {
    this.initializeStyles()
  }

  applyFilter(e: Event): void {
    const url = this.isWholeRangeCovered() ? this.element.dataset.urlForRemove : this.urlForApply()
    if (!url) return

    e.preventDefault()
    this.priceLinkTarget.setAttribute("href", url)
    this.priceLinkTarget.click()

    selectContentEvent("Search Filters", "Price Filter", this.selectedRangeAsString())
    filteredNavigationType()
  }

  lowerPriceChanged(e: Event): void {
    const input = e.target as HTMLInputElement
    const value = +input?.value
    if (!value) return

    e.preventDefault()
    this.updateLowerPrice(value)
  }

  upperPriceChanged(e: Event): void {
    const input = e.target as HTMLInputElement
    const value = +input?.value
    if (!value) return

    e.preventDefault()
    this.updateUpperPrice(value)
  }

  private initializeStyles(): void {
    const lowerValue = +this.lowerInputTarget.value
    const upperValue = +this.upperInputTarget.value
    this.setLabel(lowerValue, this.lowerLabelTarget)
    this.setLabel(upperValue, this.upperLabelTarget)
    this.setActiveTrack(lowerValue, upperValue)
  }

  private isWholeRangeCovered(): boolean {
    return +this.lowerInputTarget.value === 0 && +this.upperInputTarget.value === this.maxValue
  }

  private selectedRangeAsString(): string {
    return `${this.lowerInputTarget.value}-${this.upperInputTarget.value}`
  }

  private setActiveTrack(lowerValue: number, upperValue: number): void {
    const left = 100 * lowerValue / this.maxValue
    const width = 100 * (upperValue - lowerValue) / this.maxValue

    this.activeTrackTarget.setAttribute(
      "style",
      `display:block;left:calc(${left}%);width:calc(${width}%);`
    )
  }

  private setLabel(value: number, label: HTMLSpanElement): void {
    const percentage = value / this.maxValue
    const px = PriceFilterController.RANGE_SLIDER_THUMBNAIL_SIZE * (1 - percentage)
    const pct = percentage * 100

    label.setAttribute("style", `display:inline-block;left:calc(${px}px + ${pct}% - 1ch)`)
    label.textContent = `£${value}`
  }

  private updateLowerPrice(value: number): void {
    const newLowerValue = Math.min(value, this.maxValue - this.stepValue)
    const upperValue = +this.upperInputTarget.value

    this.lowerInputTarget.value = newLowerValue.toString()
    this.setLabel(newLowerValue, this.lowerLabelTarget)

    if (newLowerValue >= upperValue) {
      const newUpperValue = Math.max(newLowerValue + this.stepValue, upperValue)
      this.updateUpperPrice(newUpperValue)
    } else {
      this.setActiveTrack(newLowerValue, upperValue)
    }
  }

  private updateUpperPrice(value: number): void {
    const newUpperValue = Math.max(value, this.stepValue)
    const lowerValue = +this.lowerInputTarget.value

    this.upperInputTarget.value = newUpperValue.toString()
    this.setLabel(newUpperValue, this.upperLabelTarget)

    if (newUpperValue <= lowerValue) {
      const newLowerValue = Math.min(newUpperValue - this.stepValue, lowerValue)
      this.updateLowerPrice(newLowerValue)
    } else {
      this.setActiveTrack(lowerValue, newUpperValue)
    }
  }

  private urlForApply(): string {
    const { urlPrefix, urlSuffix } = this.element.dataset

    return `${urlPrefix}${this.selectedRangeAsString()}${urlSuffix}`
  }
}
