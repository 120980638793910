const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
  fn: F,
  wait = 500
): ((...args: Parameters<F>) => void) => {
  let timeoutId: number

  return (...args: Parameters<F>): void => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => fn(...args), wait)
  }
}

export { debounce }
